import React, { useEffect, useRef, useState } from 'react';
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';

function TBVAddPharmUsers({statusIndex, person}) {
    const [processing, setProcessing]                                        = useState();

    const mainExcelRef                                                       = useRef();

    async function handleFromUploadExcelSheet(e) {
        e.preventDefault();
    
        if(mainExcelRef.current.files[0].length !== 0){
          const excelData = mainExcelRef.current.files[0];
          const data = await excelData.arrayBuffer();
          
          //Worksheet
          var workbook = XLSX.read(data);
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const dataJson = XLSX.utils.sheet_to_json(worksheet, { defval: "", raw: true });
          // Extract column names (first row of the JSON data)
        
          //const columnNames = dataJCol[0];
          const lengthRows = dataJson.length;
        
          setProcessing(true);
            try{
              
                const dataContent = {
                    "data" : dataJson,
                    "userid": person._id
                }

                const res = await axios.post(CONSTANTS.API_URL +"users/account/pharmacy/", dataContent ,{
                        headers: {
                            token: "Bearer "+ person.accessToken
                        }
                    });

                //console.log(res);
                if(res.status === 200 ){
                    const accounting     = res.data.content.accounts;
                    const addSuccess     = res.data.content.added;
                    toast.success("Out of " + accounting + " accounts," + addSuccess + " have been created created.");
                    mainExcelRef.current.value = "";

                }else {
                    toast.warning("We had a problem, please try again later.");
                }            
            }catch(err){
                console.log(err);
                toast.danger("Something went wrong, please try again later.");
            }
          setProcessing(false);          
        }else {
          toast.error("File is empty, can't upload.");
        }
    }

  return (
    <div className="tv-box">
        <h3>Add Pharmacy User Accounts</h3>
        <div className="content-tab-view">
            <p>Please uitilize an excel sheet to upload with the following columns:</p>
            <div className="pdVertical10 side-scroll">
                <table className="table txt-small1">
                    <thead>
                        <tr>
                            <th>Name</th><th>Surname</th><th>Email</th><th>Profession</th><th>Phone</th><th>PracticeNo</th>
                            <th>PharmacyTitle</th><th>Street</th><th>Town</th><th>City</th><th>Zipcode</th>
                            <th>Province</th><th>Group</th><th>GroupType</th>
                        </tr>
                    </thead>
                </table>
            </div>

            <div className="pd10 mgtop10">
                Add Excell Sheet
                <form onSubmit={handleFromUploadExcelSheet} encType="multipart/form-data">
                    <div className="form-group">
                        <input type="file" ref={mainExcelRef} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" className="form-control" required/>
                    </div>
                    <div className="form-group button-row">
                        <button className="btn btn-danger">Import</button>
                    </div>
                </form>
            </div>

            {
                processing && (
                    <div className="img-source">
                    <img src={CONSTANTS.BLUE_PROCESSING} className="proc" />
                    </div>
                )
            }
        </div>
    </div>
  )
}

export default TBVAddPharmUsers