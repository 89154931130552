import React, { useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { toast } from 'react-toastify';
import PrinterList from '../Other/PrinterList';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';

function DlgInvoiceList({showInvoiceList, setShowInvoiceList, listContent, subPharmDetails, banking, person}) {
    const [processing, setProcessing]                            = useState(false);

    const [selectedInvoices, setSelectedInvoices]                = useState([]);

    const [invoiceScripts, setInvoiceScripts]                    = useState([]);

    useEffect(() => {
       if(showInvoiceList && listContent.length >0){
         setUpListOfScripts();
       }
    },[showInvoiceList])

    useEffect(() => {
      if(invoiceScripts.length > 0){
        updateScriptsInvoices();
      }
    },[invoiceScripts])

    
    const setUpListOfScripts = async () => {
      try {

        var unprocessedInvoices = listContent.filter(item => item.processed === false);
        const processInvoiceList = unprocessedInvoices.filter(item => item.payment.payType === "Levi" && item.status.text === "Downloaded")
               
        setSelectedInvoices(processInvoiceList);
        
      }catch(err){
        console.log(err);
      }
    }

    const handleInvoiceList = async () => {
      
        const d = new Date();
        const dateString = d.getFullYear() + "-"+ (d.getMonth() + 1) + "-" + d.getDate();

        const dataObject = {
            "pharmacyTitle": subPharmDetails.title,
            "pharmacyProvince": subPharmDetails.province,
            "pharmacyEmail": subPharmDetails.email,
            "bankDetails": banking,
            "invoice" : selectedInvoices,
            "currentDate": dateString
        }
        
        const blob = await pdf(<PrinterList dataScriptObject={dataObject} />).toBlob();
        
        saveAs(blob, "Invoice_LIST_" +  Math.round(d.getTime()) + "_" + dateString + ".pdf");
        const codes = dataObject.invoice.map((invoice) => invoice.scriptcode);
        setInvoiceScripts(codes);
    }
    
    const updateScriptsInvoices = async () => {
      
      try{
        const obInvoice = {
          invoices : invoiceScripts
        }
        const ming = await axios.put(CONSTANTS.API_URL + "scripts/invoices/processed/update", obInvoice,{
          headers: { 
            'token': 'Bearer ' + person.accessToken
          }
        });
        
        //console.log(ming);
        toast.success(ming.data.message);
      }catch(er){
        console.log(er);
      }
    }

  return (
    <Dialog header="Invoice List" visible={showInvoiceList} onHide={() => setShowInvoiceList(false)} style={{ width: '60vw' }}
      footer={
        <div>      
            <Button className="btn btn-picky" label="Close" onClick={() => setShowInvoiceList(false)} />
        </div>
        }>
        <div className="modal-body-content">
          <div className="data-item list-scripts">
            {
                selectedInvoices &&
                selectedInvoices.length > 0 ? (
                    <div className="invoice-outline">
                        <div className="info-invoice mgbot10">
                            <span onClick={() => handleInvoiceList()} className="dwn-now">
                              Download
                            </span> an invoice list of the pharmacy. 

                        </div>
                        <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>NO</th>
                                <th>Title</th>
                                <th>Script</th>
                                <th>Expiry</th>
                                <th>Version</th>
                                <th>Type</th>                                
                            </tr>
                        </thead>
                        <tbody>
                            {
                                selectedInvoices.map((contx, index) => {
                                    return (<tr key={index}>
                                        <td>
                                            {index + 1}
                                        </td>
                                        <td>
                                            {contx.title}
                                        </td>
                                        <td>
                                            {contx.scriptcode}
                                        </td>
                                        <td>
                                            {new Date(contx.expiryDate).toLocaleDateString(undefined, {
                                                                        year: 'numeric',
                                                                        month: 'long',
                                                                        day: 'numeric',
                                                                    })}
                                        </td>
                                        <td>
                                            {contx.versionCode}
                                        </td>
                                        <td>
                                            {contx.payment.payType}
                                        </td>
                                    </tr>)
                                })
                            }
                        </tbody>
                    </table>
                    </div>
                )
                :
                <h3>All the scripts have been processed.</h3>
            }
          </div>
            {
                processing && (
                    <div className="img-source">
                      <img src={CONSTANTS.BLUE_PROCESSING} className="proc" />
                    </div>
                )
            }
      </div>
    </Dialog>
  )
}

export default DlgInvoiceList