import React, { useEffect, useState } from 'react'
import DlgCreatePharm from '../Components/Dialogs/DlgCreatePharm'
import { useSelector } from 'react-redux'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import * as CONSTANTS from "../CONSTANTS";
import { Link } from 'react-router-dom';
import axios from 'axios';

function PharmacyDetails() {
  const {user}                                                = useSelector((state) => state.auth);
  const [processing, setProcessing]                           = useState(false);

  const [pharmacyList, setPharmacyList]                       = useState([]);
  const [pharmacyListArchive, setPharmacyListArchive]         = useState([]);
  const [userList, setUsersList]                              = useState([]);
  const [showPharmCreation, setShowPharmCreate]               = useState();
  const [globalFilter, setGlobalFilter]                       = useState('');
  const [selectedPharm, setSelectedPharm]                     = useState(null);
  const [pharmMade, setPharmMade]                             = useState(0);

  useEffect(() => {
    getAllThePharmacies();
    getAllUsersNotAdmin();

  },[pharmMade]);

  function handleTableFilter(event) {
   
        const newData = pharmacyListArchive.filter(row => {
            const searchText = event.toLowerCase();

          return ( 
            row.title.toLowerCase().includes(searchText) ||
            row.province.toLowerCase().includes(searchText) ||
            row.email.toLowerCase().includes(searchText) ||
            row.practicenumber.toLowerCase().includes(searchText) )
        });
        setPharmacyList(newData);
    }
    //////////___
    const header = (
      <div className="table-header flex justify-content-between">
        <h4 className="mx-0 my-1"></h4>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => handleTableFilter(e.target.value)} placeholder="Search Pharmacy..." />
        </span>
      </div>
    );
    //////////___
    //////////___
     const linkPharmacyTemplate = (rowData) => {
      return <Link to={"/pharmacy/" + rowData._id} className="btn btn-rescure btsm1">View</Link> 
    }
    //////////___
  const getAllThePharmacies = async () => {
   
    setProcessing(true);
    try{
        const res = await axios.get(CONSTANTS.API_URL +"pharmacy/list", {
             headers: {
                 token: "Bearer "+ user.accessToken
             }
         });
         
         setPharmacyList(res.data);
         setPharmacyListArchive(res.data);
         setProcessing(false);
    }catch(erros){
     console.log(erros);   
     setProcessing(false);  
    }
   }

   const getAllUsersNotAdmin = async () => {
   
    try{
        const res = await axios.get(CONSTANTS.API_URL +"users/list/vnoadmin", {
             headers: {
                 token: "Bearer "+ user.accessToken
             }
         });

         setUsersList(res.data);
 
    }catch(erros){
     console.log(erros);
     
    }
   }

   const viewAddressTemplate = (rowData) => {     
    return rowData.address.street + ", "+ rowData.address.town + " " + rowData.address.city + " " + rowData.address.zipcode;
  }

  return (
    <div className="main-standard">
      <h2 className="lbl-title-page">Pharmacies</h2>
      <div className="card">
         <div className="card-body">
            <div className="row-box">
               <DlgCreatePharm 
                    showPharmCreation={showPharmCreation} 
                    setShowPharmCreate={setShowPharmCreate} 
                    userList={userList}
                    pharmMade={pharmMade}
                    setPharmMade={setPharmMade}
                    person={user} 
                  />

                 <Button
                      label="Create Pharmacy"
                      className="btn btn-rescure btsm1 mr-2"
                      onClick={() => setShowPharmCreate(true)}
                    />
            </div>
            <div className="row-data">
               {
                  pharmacyList && (
                    <DataTable
                        value={pharmacyList}
                        header={header}
                        className="min-w-full"
                        paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} pharmacy"
                        responsiveLayout="scroll"
                        globalFilter={(globalFilter) ? globalFilter : ''}
                        selection={selectedPharm}
                        onSelectionChange={e => setSelectedPharm(e.value)}
                        rowClassName={(rowData) => rowData.seen ? 'seen-row' : 'unseen-rs'}
                      >

                        <Column field="title" header="Title"></Column>
                        <Column field="practicenumber" header="License Code"></Column>
                        <Column field="email" header="Email"></Column>
                        <Column field="grouptype" header="Type"></Column>
                        <Column field="province" header="Province"></Column>   
                        <Column field="approve" header="Approved"></Column>     
                        <Column header="Address" body={viewAddressTemplate}></Column>                            
                        <Column header="View" body={linkPharmacyTemplate}></Column>                     
                    </DataTable>
                  )
              }
            </div>
         </div>
       </div>
       {
           processing && (
            <div className="position-center">
              <img src={CONSTANTS.BLUE_PROCESSING} className="proc" />
            </div>
           )
        }
    </div>
  )
}

export default PharmacyDetails