import React, { useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { toast } from 'react-toastify';

function DlgListUsers({showAdminUserList, setShowAdminUserList, person}) {
    const [processing, setProcessing]                            = useState(false);
    const [usersList, setUsersList]                              = useState([]);
    const [mess, setMessage]                                     = useState("");


    const nameRef                                     = useRef();
    const surnameRef                                  = useRef();
    const emailAddressRef                             = useRef();
    const passwordRef                                 = useRef();
    const phonenumberRef                              = useRef();
    const proffessionRef                              = useRef();

    useEffect(() => {
      collectAdminUsers();
    },[mess])


    const collectAdminUsers = async () => {
      try{

        const res = await axios.get(CONSTANTS.API_URL +"users/list/admins", {
              headers: {
                  token: "Bearer "+ person.accessToken
              }
          });
        
          setUsersList(res.data);

      }catch(err){
        console.log(err);
      }
    }

    async function HandleCreatingAdminUser(e) {
      e.preventDefault();  
      setProcessing(true);   
  
      try{
          const personContent = {
              "name" : nameRef.current.value,
              "surname" : surnameRef.current.value,
              "email": emailAddressRef.current.value,
              "role": "admin",
              "phonenumber": phonenumberRef.current.value,
              "proffession": proffessionRef.current.value
             }
       

          
             const responseReg = await axios.post(CONSTANTS.API_URL+"auth/register/admin", personContent);
             setMessage("Thank you");
             setProcessing(false);
             e.target.reset();
             toast.success("You successfully created a Admin.");
              
      } catch (err){
  
        console.log(err);
        setProcessing(false);
     
      }       
    }
  return (
    <Dialog header="List User Admins" visible={showAdminUserList} onHide={() => setShowAdminUserList(false)} style={{ width: '60vw' }}
      footer={
        <div>      
            <Button className="btn btn-picky" label="Close" onClick={() => setShowAdminUserList(false)} />
        </div>
        }>
        <div className="modal-body-content">
          <div className="data-item list-users">
            <div className="flexme">
                <div className="flex_sub70">
                  <div className="pd10">
                    <h4>Admin User</h4>
                  {
                    usersList && (
                      <table className="table table-striped">
                        <thead>
                          <th>Name</th>
                          <th>Surname</th>
                          <th>Email</th>
                        </thead>
                        <tbody>
                          {
                            usersList.map((admin, index) => {
                              return (<tr key={index}>
                                <td>{admin.name}</td>
                                <td>{admin.surname}</td>
                                <td>{admin.email}</td>
                              </tr>)
                            })
                          }
                        </tbody>
                      </table>
                    )
                  }
                  </div>
                </div>
                <div className="flex_sub30">
                  <div className="frm-add">
                      <h4>Add Admin User</h4>
                        <form onSubmit={HandleCreatingAdminUser}>                            
                            <div className="form-group">
                                  <div className="lbldesc">Name*:</div>
                                  <input type="text" className="form-control wide100" ref={nameRef} required/>
                            </div>     
                            <div className="form-group">
                                  <div className="lbldesc">Surname*:</div>
                                  <input type="text" className="form-control wide100" ref={surnameRef} required/>
                            </div>  
                            <div className="form-group">
                                  <div className="lbldesc">Email*:</div>
                                  <input type="email" className="form-control wide100" ref={emailAddressRef} required/>
                            </div>   
                            <div className="form-group">
                                  <div className="lbldesc">Password*:</div>
                                  <input type="text" className="form-control wide100" ref={passwordRef} defaultValue={"PASSWORD1"} required/>
                            </div>  
                            <div className="form-group">
                                  <div className="lbldesc">Role type:</div>
                                  Admin
                            </div>   
                            <div className="form-group">
                                  <div className="lbldesc">Phone Number:</div>
                                  <input type="text" className="form-control wide100" ref={phonenumberRef} />
                            </div>
                            <div className="form-group">
                                  <div className="lbldesc">Profession:</div>
                                  <input type="text" className="form-control wide100" ref={proffessionRef} />
                            </div>  
                            <div className="form-group">
                                  <button type="submit" className="btn btn-rescure" disabled={processing}>Create</button>                             
                            </div>
                  </form>
                  </div>
                </div>
            </div>
          </div>
            {
                processing && (
                    <div className="img-source">
                      <img src={CONSTANTS.BLUE_PROCESSING} className="proc" />
                    </div>
                )
            }
      </div>
    </Dialog>
  )
}

export default DlgListUsers