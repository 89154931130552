import React, { useRef, useState } from 'react'
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { toast } from 'react-toastify';

function DlgPasswordReset({showDiaPasswordReset, setShowDiaPasswordReset}){
    const [processing, setProcessing]                               = useState(false);
    const [resetEmailAddress, setResetEmailAddress]                 = useState("");
    const [showPasswordUpdate, setShowPasswordUpdate]               = useState(false)
    const [codeVerification, setCodeVerification]                   = useState("");
    const [newCode, setNewCode]                                     = useState();

    const newPasswordRef                                            = useRef();
    const confirmPasswordRef                                        = useRef();
    const emailUserRef                                              = useRef();  

  const handleCheckEmailAddress = async () => {
    setProcessing(true);
      try{
    
        let emailCheckTest = isValidEmail(emailUserRef.current.value);

        if(emailCheckTest){
            const respFeed = await axios.put(CONSTANTS.API_URL + "auth/reset/admin/password/"  + emailUserRef.current.value);
           
            if(respFeed.status === 200){
              setResetEmailAddress(emailUserRef.current.value);
              toast.success("Please check your email for the code.");
              setCodeVerification(respFeed.data.code);
            }else {
              toast.warning("Issues sending an email. Please try again later")    
            }
        }else {
          toast.error("Email not valid");
        }
        setProcessing(false);
      }catch(err){
        console.log(err);
        if(err.response.status === 401){
          toast.error(err.response.data);
        }else {
          toast.error("Something went wrong, please try again later.");
        }
        setProcessing(false);
      }
  }

  function isValidEmail(email){
      return /\S+@\S+\.\S+/.test(email);
  }

  const handleChangeConfirm = (event) => {
    setNewCode(event.target.value);
    if(event.target.value === codeVerification){
      setShowPasswordUpdate(true);
    }else {
      if(showPasswordUpdate === true){
        setShowPasswordUpdate(false);
      }     
    }
  }

  const handleAdminResetPassword = async() => {
    if(newPasswordRef.current.value === confirmPasswordRef.current.value){
       if(newPasswordRef.current.value.length > 4){

        const changePassword = {
            "email" : resetEmailAddress,
            "code" : codeVerification,
            "password": newPasswordRef.current.value
        }
        
        // console.log("Shook Challenge");
        // console.log(changePassword);
        setProcessing(true);
        try{
         
          const respFeed = await axios.put(CONSTANTS.API_URL + "users/admin/update/" , changePassword);
          //console.log(respFeed);
          if(respFeed.status === 200){
            toast.success("Update was successfull, please login.");

            setTimeout(function (){
              setCodeVerification("");
              setResetEmailAddress("");

              newPasswordRef.current.value     = "";
              confirmPasswordRef.current.value = "";
              setShowPasswordUpdate(false);
            }, 2000);

          }

         setProcessing(false);
        }catch(err){
          console.log(err);
          setProcessing(false);
        }
       }else {
         toast.warning("Password is too short.");
       }
    }else {
      toast.warning("Password is not the same.");
    }
  }

  return (
    <Dialog header="Reset Password" visible={showDiaPasswordReset} onHide={() => setShowDiaPasswordReset(false)} style={{ width: '50vw' }}
    footer={
        <div>      
            <Button className="btn btn-rescure" label="Close" onClick={() => setShowDiaPasswordReset(false)} />
        </div>
        }>
         <div className="modal-body-content">
                <h3>Reset your Password</h3>
              {
                codeVerification.length === 0 && (
                  <div className="email-modal">
                      <div className="cont-input mgbot10">
                        <input className="form-control special-input" type="text" placeholder="Please type in your email Address" ref={emailUserRef} />
                      </div>
                      <div className="cont-button">
                        <button className="btn btn-rescure-login" onClick={() => handleCheckEmailAddress()}>Submit</button>
                      </div>
                  </div>
                )
            }

            {
                codeVerification.length === 5 && (
                  <div className="email-modal">
                      <div className="cont-input mgbot10">
                         <p>A validation code has been sent to your email. Please use it in order to update your password.</p>
                        <input className="form-control special-input" type="text" placeholder="Enter code" value={newCode} onChange={handleChangeConfirm} />
                      </div>
                      {
                        showPasswordUpdate && (
                          <>
                            <p>Ensure that the password match.</p>
                            <div className="cont-input mgbot10">                        
                              <input className="form-control special-input" type="password" placeholder="Enter New Password" ref={newPasswordRef} />
                            </div>
                            <div className="cont-input mgbot10">                        
                              <input className="form-control special-input" type="password" placeholder="Enter New Password again." ref={confirmPasswordRef} />
                            </div>
                            <div className="cont-button">
                              <button className="btn btn-rescure-login" onClick={() => handleAdminResetPassword()}>Submit</button>
                            </div>
                          </>
                        )
                      }
                      
                  </div>
                )
              }
            
        {
                processing && (
                    <div className="img-source">
                    <img src={CONSTANTS.BLUE_PROCESSING} className="proc" />
                    </div>
                )
           }
        </div>
    </Dialog>

  )
}

export default DlgPasswordReset