import React, { useEffect, useState } from 'react'
import DlgCreateUsers from '../Components/Dialogs/DlgCreateUsers';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useSelector } from 'react-redux';
import * as CONSTANTS from "../CONSTANTS";
import axios from 'axios';
import DlgListUsers from '../Components/Dialogs/DlgListUsers';


function UsersList() {

  const {user}                                           = useSelector((state) => state.auth);
  const [globalFilter, setGlobalFilter]                  = useState('');  
  const [userList, setUsersList]                         = useState([]);
  const [selectedPerson, setSelectedPerson]              = useState(null);

  const [showUserCreation, setShowUserCreation]          = useState();
  const [showAdminUserList, setShowAdminUserList]        = useState(false);

  useEffect(() => {
    getAllUsersNotAdmin();
  },[]);

  //////////___
  const header = (
    <div className="table-header flex justify-content-between">
      <h4 className="mx-0 my-1"></h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search Users..." />
      </span>
    </div>
  );

  //////////____
  const viewPharmacyName = (rowData) => {
    return <div className="clip-text">{rowData.pharmacyTitle}</div>
  }
  /////////____
  const viewPharmacyRegion  = (rowData) => {
    return <div className="clip-text">{rowData.province}</div>
  }
   /////////____
   const viewPharmacyGroupType  = (rowData) => {
    return <div className="clip-text">{rowData.grouptype} - {rowData.groupdetail}</div>
  }
  /////////____
   const getAllUsersNotAdmin = async () => {
    // console.log(user);
 
    try{
        const res = await axios.get(CONSTANTS.API_URL +"users/list/withpharms", {
             headers: {
                 token: "Bearer "+ user.accessToken
             }
         });
        
         setUsersList(res.data);
 
    }catch(erros){
     console.log(erros);     
    }
   }

  return (
    <div className="main-standard">
      <h2 className="lbl-title-page">Employees</h2>
      <div className="card">
         <div className="card-body">
            <div className="row-box">
               <DlgListUsers 
                    setShowAdminUserList={setShowAdminUserList} 
                    showAdminUserList={showAdminUserList} 
                    person={user} 
                  />

               <DlgCreateUsers 
                    setShowUserCreation={setShowUserCreation} 
                    showUserCreation={showUserCreation} 
                    person={user} 
                  />


                 <Button
                      label="Create User"
                      className="btn btn-rescure btsm1 mr-2"
                      onClick={() => setShowUserCreation(true)}
                    />
            </div>
            <div className="row-data">
               {
                  userList && (
                    <DataTable
                      value={userList}
                      header={header}
                      className="min-w-full"
                      paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
                      responsiveLayout="scroll"
                      globalFilter={(globalFilter) ? globalFilter : ''}
                      selection={selectedPerson}
                      onSelectionChange={e => setSelectedPerson(e.value)}
                    >
                        <Column field="name" header="Name"></Column>
                        <Column field="surname" header="Surname"></Column>
                        <Column field="email" header="Email"></Column>
                        <Column field="profession" header="Profession"></Column>  
                        <Column header="Pharmacy" body={viewPharmacyName}></Column>   
                        <Column header="Provice" body={viewPharmacyRegion}></Column>
                        <Column header="Group" body={viewPharmacyGroupType}></Column>                        
                    </DataTable>
                  )
              }
            </div>
            <div className="row-box">
        
                 <Button
                      label="Admin Users"
                      className="btn btn-rescure btsm1 mr-2"
                      onClick={() => setShowAdminUserList(true)}
                    />
            </div>
         </div>
       </div>
      </div>

  )
}

export default UsersList