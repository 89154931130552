import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import { faHome, faArrowCircleDown, faStore, faUsers , faCog, faBarcode, faBolt, faFile} from '@fortawesome/free-solid-svg-icons'
import './sidebar.css';


function Sidebar({member}) {
    const [sideClass, setSideClass]             = useState("full-side");
    const [showSubMenu, setShowSubMenu]         = useState(false);

    const toggleSubMenu = () => {
        setShowSubMenu(!showSubMenu);
    };
    
  return (
    <div className={"bs-side " + sideClass}>
        <div className="sidebarMenu">
            <h3 className="sidebarTitle logo-spark">Dashboard</h3>  
            <div className="sidebarTitle logo-mobile"><FontAwesomeIcon icon={faBarcode} /></div>  
            <ul>
                <li className="sidebarListItem">
                    <div className="col-item-block">
                        <Link to="/" className="link-nav-item-side">
                        <span className="sd-icon"><FontAwesomeIcon icon={faHome} /></span> 
                        <span className="name-Item">HOME</span>
                        </Link>
                    </div>
                </li>

                <li className="sidebarListItem">
                    <div className="col-item-block">
                    <Link to="/users" className="link-nav-item-side">
                            <span className="sd-icon"> <FontAwesomeIcon icon={faUsers} /></span> 
                            <span className="name-Item">Users</span>
                        </Link>
                    </div>
                </li>
               
           
                <li className="sidebarListItem">
                    <div className="col-item-block">
                       <Link to="/pharmacies" className="link-nav-item-side">
                            <span className="sd-icon"> <FontAwesomeIcon icon={faStore} /></span> 
                            <span className="name-Item">Pharmacies</span>
                        </Link>
                    </div>
                </li>
                <li className="sidebarListItem">
                        <div className="col-item-block">
                            <div className="link-nav-item-side dropp-item" onClick={toggleSubMenu}>
                                <span className="sd-icon"><FontAwesomeIcon icon={faFile} /></span>
                                <span className="name-Item">Menu Scripts</span>
                                <span className="right-sd-icon"> <FontAwesomeIcon icon={faArrowCircleDown} /></span>
                            </div>
                            {/* Submenu */}
                            {showSubMenu && (
                                <ul className="sub-menu">
                                    <li><Link to="/active-scripts">Active</Link></li>
                                    <li><Link to="/expired-scripts">Expired Scripts</Link></li>
                                    <li><Link to="/cancelled-scripts">Cancelled</Link></li>                                    
                                </ul>
                            )}
                        </div>
                </li>
                <li className="sidebarListItem">
                    <div className="col-item-block">
                       <Link to="/knowledge-base" className="link-nav-item-side">
                            <span className="sd-icon"> <FontAwesomeIcon icon={faBolt} /></span> 
                            <span className="name-Item">Knowledge Base</span>
                        </Link>
                    </div>
                </li>
                <li className="sidebarListItem">
                        <div className="col-item-block">
                        <Link to="/settings" className="link-nav-item-side">
                                <span className="sd-icon"> <FontAwesomeIcon icon={faCog} /></span> 
                                <span className="name-Item">Setting</span>
                            </Link>
                        </div>
                </li>
            </ul>
        </div>
    </div>
  )
}

export default Sidebar