import React from 'react';

function Tabs({ label, isActive, onClick }) {
  return (
    <div className="box-container-tab">
        <div className={`tab ${isActive ? 'active' : ''}`} onClick={onClick}>
            {label}
        </div>
    </div>
  )
}

export default Tabs