import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { toast } from 'react-toastify';
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import React, { useRef, useState } from 'react'

function DlgVideoUpdate({showUpdateModalFormat, setShowUpdateModalFormat, videoContent, action, person}) {

    const [processing, setProcessing]                                   = useState(false);

    const titleVideoRef                                                 = useRef();
    const descriptionRef                                                = useRef();
    const videoLinkRef                                                  = useRef();

    const handleEditVideoItem = async (e) => {
        e.preventDefault();

        try{
            setProcessing(true);

            const objectContent = {
                "id" : videoContent._id,
                "title"  : titleVideoRef.current.value,
                "description" : descriptionRef.current.value,
                "videolink" : videoLinkRef.current.value
            }

            ///console.log(objectContent);
            
            const editObs = await axios.put(CONSTANTS.API_URL + "videos/edit/content", objectContent, {
                headers: {
                    token: "Bearer "+ person.accessToken
                }
            })
            //console.log(editObs);
            
            if(editObs.status === 200){
                toast.success("You have successfully made updates.");
                setTimeout(function () {
                    window.location.reload();
                }, 4000);
            }
            setProcessing(false);
        } catch (err){
            console.log(err);
            setProcessing(false);
            toast.error("Something went wrong, please try again later.")
        }
    }

    const handleConfirmDelete = async () => {
        try{
            setProcessing(true);

            const idKey = {
                "idkey" : videoContent._id
            }

            const deleteObs = await axios.put(CONSTANTS.API_URL + "videos/delete/item", idKey, {
                    headers: {
                        token: "Bearer "+ person.accessToken
                    }
                });

                //console.log(deleteObs);
            
            if(deleteObs.status === 200){
                toast.success("Video has been deleted Successfully.");

                setTimeout(function () {
                    window.location.reload();
                }, 4000);
            }
            setProcessing(false);

        }catch(err){
            console.log(err);
            setProcessing(false);
            toast.error("Something went wrong, please try again later.");
        }
    }

  return (
    <Dialog header="Format Update" visible={showUpdateModalFormat} onHide={() => setShowUpdateModalFormat(false)} style={{ width: '60vw' }}
      footer={
        <div>      
            <Button className="btn btn-picky" label="Close" onClick={() => setShowUpdateModalFormat(false)} />
        </div>
        }>
        <div className="modal-body-content">        
        
            {
                videoContent && (
                    <div className="data-item list-video">
                        {
                            action === "edit" ?
                            <div className="ctx-item edit-item">
                                <h3>Edit</h3>
                                <form onSubmit={handleEditVideoItem}>
                                    <div className="form-group">
                                        <div className="lbl">Title</div>
                                        <input type="text" className="form-control" ref={titleVideoRef} defaultValue={videoContent.title} />
                                    </div>
                                    <div className="form-group">
                                        <div className="lbl">Link URL</div>
                                        <input type="text" className="form-control" ref={videoLinkRef} defaultValue={videoContent.videourl} />
                                    </div>
                                    <div className="form-group">
                                        <div className="lbl">Description</div>
                                        <textarea className="form-control" ref={descriptionRef}>{videoContent.description}</textarea>
                                    </div>
                                    <div className="form-group">
                                        <button className="btn btn-warning btn-edit-item">Submit</button>
                                    </div>
                                </form>
                            </div>
                            :
                            <div className="ctx-item delete-item">

                                <h3>Delete</h3>
                                <p>{videoContent.title}</p>
                                <button className="btn btn-danger" onClick={() => handleConfirmDelete()}>Confirm Delete</button>
                            </div>
                        }
                    </div>
                )
            }

        {
           processing && (
            <div className="position-center">
              <img src={CONSTANTS.BLUE_PROCESSING} className="proc" />
            </div>
           )
        }
    </div>
    </Dialog>
  )
}

export default DlgVideoUpdate