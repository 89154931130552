import React, { useEffect, useState } from 'react'
import axios from 'axios';
import * as CONSTANTS from "../CONSTANTS";
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import { faStore, faScroll, faStoreSlash} from '@fortawesome/free-solid-svg-icons'
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement
} from 'chart.js'


ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement
)

function Home() {
  const [pharmCount, setPharmCount]                           = useState(0);
  const [scriptCount, setScriptCount]                         = useState(0);
  const [pharmSeenTotal, setPharmSeenTotal]                   = useState(0);
 
  const [lineDataElements, setLineDataElements]             = useState();

  const options = {};

  useEffect(() => {
    collectInitial();
  },[])

  const collectInitial = async () => {

      try {
        
          //1st Totals
          const resScriptData   = await axios.get(CONSTANTS.API_URL +"scripts/menu/total/list/");
          const resPharmData    = await axios.get(CONSTANTS.API_URL +"pharmacy/stores/total/list");
          const resPharmCount   = await axios.get(CONSTANTS.API_URL +"pharmacy/count/all/seen");
        
          setScriptCount(resScriptData.data.total);
          setPharmCount(resPharmData.data.total);

          // console.log("resPharmCount.data");
          // console.log(resScriptData.data.total);
          // console.log(resPharmCount.data);
          setPharmSeenTotal(resPharmCount.data.totalCount)
          //2nd Line data
          const resLineData  = await axios.get(CONSTANTS.API_URL +"scripts/scripts-per-month");

          if(resLineData.data.length > 0){
            const lineLabels = ["Jan", "Feb", "Mar","Apr", "May", "Jun","Jul","Aug", "Sep" , "Oct", "Nov", "Dec"];
            
            const newArray = Array(12).fill(0); // Initialize new array with 12 elements, all set to 0

            resLineData.data.forEach(item => {
                newArray[item.month - 1] = item.count; // Subtracting 1 because months are 1-indexed
            });

            setLineDataElements({
              labels: lineLabels,
              datasets: [{
                data: newArray
              }]
            })
          }
        } catch (err) {
          console.log(err);
          console.log("ERRRRRRR");
        }      
  }

  return (
    <div className="main-standard-version">
      <h2 className="lbl-title-page">Home</h2>
      <div className="card">
            <div className="card-body">
                <div className="row rxe">
                  <div className="col-md-3">
                    <div className="basic-item">
                      {
                        pharmCount && (
                          <div className="item-data">
                            <div className="hm-title">PHARMACY</div>
                            <h3 className="shield-item">{pharmCount}</h3>
                            <div className="hme-icon"><FontAwesomeIcon icon={faStore} /></div> 
                          </div>
                        )
                      }
                    </div>
                    
                  </div>
                  <div className="col-md-3">
                    <div className="basic-item">
                      {
                        scriptCount && (
                          <div className="item-data">
                            <div className="hm-title">SCRIPTS</div>
                            <h3 className="shield-item">{scriptCount}</h3>
                            <div className="hme-icon"><FontAwesomeIcon icon={faScroll} /></div> 
                          </div>
                        )
                      }
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="basic-item">
                      {
                        lineDataElements && (
                          <div className="graph-data">
                               <div className="hm-title">NUMBER OF SCRIPTS</div>
                              <Line data={lineDataElements} options={options} />
                          </div>
                        )
                      }
                    </div>
                  </div>
                  <div className="col-md-3">
                  <div className="basic-item">
                      {
                        pharmSeenTotal && (
                          <div className="item-data">
                            <div className="hm-title">SEEN PHARMACIES</div>
                            <h3 className="shield-item">{pharmSeenTotal}</h3>
                            <div className="hme-icon"><FontAwesomeIcon icon={faStoreSlash} /></div> 
                          </div>
                        )
                      }
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Home