import React from 'react'
import { useSelector } from 'react-redux';

function TBVProfile() {
  const {user}                                       = useSelector((state) => state.auth);

  //console.log(user)

  return (
    <div className="tv-box">
        <h3>Profile</h3>
        <div className="content-tab-view">
            <table className="table table-striped">
              <tbody>
                <tr>
                    <td>
                      Name
                    </td>
                    <td>
                      :
                    </td>
                    <td>
                      {user.name}
                    </td>
                </tr>
                <tr>
                    <td>
                      Surname
                    </td>
                    <td>
                      :
                    </td>
                    <td>
                      {user.surname}
                    </td>
                </tr>
                <tr>
                    <td>
                      Email
                    </td>
                    <td>
                      :
                    </td>
                    <td>
                      {user.email}
                    </td>
                </tr>
                <tr>
                    <td>
                      Bio
                    </td>
                    <td>
                      :
                    </td>
                    <td>
                      {user.bio}
                    </td>
                </tr>
              </tbody>
            </table>
        </div>
    </div>
  )
}

export default TBVProfile