
export const API_URL = "https://rescurescripts-51d8d1272bab.herokuapp.com/api/";
export const API_BASE = "https://rescurescripts-51d8d1272bab.herokuapp.com/";
export const SESSION_COOKIE = "userXRxeqsADMINISTRATORX1000";

/*
export const API_URL = "http://localhost:1211/api/";
export const SESSION_COOKIE = "userXRxeqsADMINISTRATORXLocal";
*/

export const FRONT_EMAIL = "https://resqscripts.co.za/emailers/emailsend.php";
export const SMS_SEND = "https://cjmarketing.co/bulksms/index.php?";
export const SMALL_PROCESSING = "https://api.picnick.co.za/assets/smallprocess.gif";
export const BLUE_PROCESSING = "http://cjmarketing.co/image_directory/blue-load.gif";
export const IMAGE_PROFILE = "https://newclient.cjdsa.co.za/images/base/profile.jpg";