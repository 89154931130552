import React, { useEffect, useState } from 'react';
import * as CONSTANTS from "../CONSTANTS";
import axios from 'axios';
import { useSelector } from 'react-redux';
import './settings.css';
import TBVEmailNotifications from '../Components/Other/TBVEmailNotifications';
import TBVProfile from '../Components/Other/TBVProfile';
import TBVMedicalAid from '../Components/Other/TBVMedicalAid';
import TBVMedicationToken from "../Components/Other/TBVMedicationToken";
import TBVAddPharmUsers from '../Components/Other/TBVAddPharmUsers';
import TBVStorePricing from '../Components/Other/TBVStorePricing';
import { useNavigate } from 'react-router-dom';

function Settings() {
    const {user}                                                   = useSelector((state) => state.auth);
    const [activeIndex, setActiveIndex]                            = useState(1);

    const navigate                                                 = useNavigate();
    
    //functions
    const toggleSelectedTab = (index) => {
        setActiveIndex(index);
    }   

    if(user === null){
        window.location.replace("/login");
    }
    
  return (
    <div className="main-standard">
        <h2 className="lbl-title-page">Settings</h2>
        <div className="card">
           <div className="card-body">
             <div className="tab-dashboard-item">
                    <div className="side-dash-settings-nav">                   
                        <div  className={activeIndex === 1 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleSelectedTab(1)}>
                        Email Notification
                        </div>
                        <div  className={activeIndex === 2 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleSelectedTab(2)}>
                            Profile
                        </div> 
                        <div  className={activeIndex === 3 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleSelectedTab(3)}>
                            Medical Aids
                        </div>
                        <div className={activeIndex === 4 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleSelectedTab(4)}>
                            Medication Token
                        </div>
                        <div className={activeIndex === 5 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleSelectedTab(5)}>
                            Add Excel Pharmacy User Accounts
                        </div>    
                        <div className={activeIndex === 6 ? "tabs active-tabs" : "tabs"}
                            onClick={() => toggleSelectedTab(6)}>
                            Payments
                        </div>                      
                    </div>

                    <div className="tab-content-box">
                        <div className={activeIndex === 1 ? "content-tab content-active" : "content-tab"}>
                            <TBVEmailNotifications person={user} />                           
                        </div>
                        <div className={activeIndex === 2 ? "content-tab content-active" : "content-tab"}>
                            <TBVProfile person={user} />                           
                        </div>
                        <div className={activeIndex === 3 ? "content-tab content-active" : "content-tab"}>
                            <TBVMedicalAid statusIndex={activeIndex}  person={user} />
                         </div>
                         <div className={activeIndex === 4 ? "content-tab content-active" : "content-tab"}>
                            <TBVMedicationToken statusIndex={activeIndex}  person={user} />
                         </div>
                         <div className={activeIndex === 5 ? "content-tab content-active" : "content-tab"}>
                            <TBVAddPharmUsers statusIndex={activeIndex}  person={user} />
                         </div>
                         <div className={activeIndex === 6 ? "content-tab content-active" : "content-tab"}>
                            <TBVStorePricing statusIndex={activeIndex}  person={user} />
                         </div>
                    </div>
             </div>
           </div>
        </div>
   </div>
  )
}

export default Settings