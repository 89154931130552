import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify';
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import { faQuestion, faAngleUp, faCircleArrowDown, faTrash} from '@fortawesome/free-solid-svg-icons'

function TBVMedicalAid({statusIndex, person}) {

    const [medicalAidList, setMedicalAidList]                                  = useState([]);
    const [checkon, setCheckon]                                                = useState(true);
    const [process, setProcessing]                                             = useState(false);
    const [addedMedicalUpdate, setAddedMedicalUpdate]                          = useState(0);

    const [showBtnEdits, setShowBtnEdits]                                      = useState(false);
    const [activeItemView, setActiveItemView]                                  = useState(null);

    const medicalAidTitleRef                                                   = useRef();

    useEffect(() => {
        if(statusIndex === 3){
            collectMeddicalAids();
        }
    },[statusIndex, addedMedicalUpdate])


    const collectMeddicalAids = async () => {
        try{
            //console.log("TBVMedicalAid");

            setProcessing(true);

            let config = {
                method: 'put',
                maxBodyLength: Infinity,
                url: CONSTANTS.API_URL + "settings/collect/medicals",
                headers: { 
                  'token': 'Bearer ' + person.accessToken
                }
              };
              
            //))))))))
            const response = await axios.request(config);
           
            //console.log(response.data);
            setMedicalAidList(response.data);
            setProcessing(false);
        }catch(err){
            console.log(err);
            setProcessing(false);
        }
    }
    
    async function HandleAddingMedicalAid(e) {
        e.preventDefault();  
        setProcessing(true);   
    
        try{
 
           const mediSub = {
            "title" : medicalAidTitleRef.current.value,
            "status": checkon
           }
           
           const response = await axios.post(CONSTANTS.API_URL + "settings/add/medical", mediSub, {
                headers: {
                    token: "Bearer "+ person.accessToken
                }
            });

           if(response.status === 201){
            setAddedMedicalUpdate((prevValue) => prevValue + 1);

            e.target.reset();
            setProcessing(false); 
            toast.success("Successfully added the medical aid.")
           }
 
          
        } catch (err){
          setProcessing(false);   
        }       
      }

      const handleCheckboxChange = (event) => {
        setCheckon(event.target.checked); // Update the checkon state based on the checkbox status
    };

    const removeCurrentMedical = async (medItem) => {
        try{

            const response = await axios.put(CONSTANTS.API_URL + "settings/remove/medical/item", medItem, {
                headers: {
                    token: "Bearer "+ person.accessToken
                }
            });
    
           // console.log(response);
            setAddedMedicalUpdate((prevValue) => prevValue - 1);
        }catch(err){
            console.log(err);
            toast.error("Something went wrong deleting, please try again later.")
        }
    }

    const updateCurrentMedical = async (medItem) => {
        try{

            const response = await axios.put(CONSTANTS.API_URL + "settings/update/medical/item", medItem, {
                headers: {
                    token: "Bearer "+ person.accessToken
                }
            });
    
            //console.log(response);
            setAddedMedicalUpdate((prevValue) => prevValue - 1);
        }catch(err){
            console.log(err);
            toast.error("Something went wrong deleting, please try again later.")
        }
    }
  
    const toggleViewOptions = (index) => {
        setActiveItemView(activeItemView === index ? null : index);
    };

  return (
    <div className="tv-box">
        <h3>Medical Aids</h3>
        <div className="content-tab-view">
            <div className="flexme">
                    <div className="flex_sub50">
                            <div className="height-med-x">
                            {
                                    medicalAidList && (
                                        <div className="l-list-container rx-listing">
                                            <ul className="l-list no-style ">
                                                {
                                                    medicalAidList.map((medicItem, key) => {
                                                        return (<li key={key} className="tab-bar-ls group-medical-item">
                                                            <div className="">
                                                                <div className="title-bxo">{medicItem.title}</div>
                                                                <div className="show-bxo">{medicItem.mediStatus ? "Published" : "Draft"}</div>
                                                            </div>
                                                            <button className="btn clox-sect-pop" onClick={() => toggleViewOptions(key)}>
                                                                <FontAwesomeIcon icon={faQuestion} />
                                                            </button>
                                                            {
                                                                activeItemView === key && (
                                                                    <div className="view-options sect-strip">
                                                                        <div className="mid-status-sect clox-status" onClick={()=> updateCurrentMedical(medicItem)}>
                                                                            <div className="bubble-circle">
                                                                                <FontAwesomeIcon icon={faCircleArrowDown} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="mid-status-sect clox-removal" onClick={()=> removeCurrentMedical(medicItem)}>
                                                                            <div className="bubble-circle">
                                                                                <FontAwesomeIcon icon={faTrash} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        </li>)
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="flex_sub50">
                            <div className="pd20">
                                <div className="form-glim">
                                    Add Medical Aid.
                                    <form onSubmit={HandleAddingMedicalAid}>                   
                                            <div className="form-group">
                                                <div className="lbldesc">Medical Aid Title*:</div>
                                                <input type="medtitle" className="form-control wide100" ref={medicalAidTitleRef} required/>
                                            </div>
                                            <div className="form-group">
                                                <div className="lblcheckbx">Check for <strong>publication</strong> and uncheck to draft:</div>
                                                <input 
                                                    type="checkbox" 
                                                    defaultChecked={checkon}
                                                    onChange={handleCheckboxChange} 
                                                    className="form-checker"/>
                                            </div>
                                            <div className="form-group">
                                                    <button type="submit" className="btn btn-rescure" disabled={process}>Create</button>                             
                                            </div>                       
                                    </form>
                                </div>
                            </div>
                            <div className="pd10">
                                <div className=" info-dialogue">
                                    <table className="table table-striped">
                                        <thead>
                                            <th>
                                                Item
                                            </th>
                                            <th>
                                                Explaination
                                            </th>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                <FontAwesomeIcon icon={faQuestion} />
                                                </td>
                                                <td>Toggle Update publish/draft or delete.</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                <FontAwesomeIcon icon={faCircleArrowDown} />
                                                </td>
                                                <td>Update Medical Aid view status to be published or drafted.</td>
                                            </tr>
                                            
                                            <tr>
                                                <td>
                                                   <FontAwesomeIcon icon={faTrash} />
                                                </td>
                                                <td>Delete the Medical Aid.</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>                            
                    </div>
            </div>
        </div>
    </div>
  )
}

export default TBVMedicalAid