import React, { useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import { faThumbsUp, faThumbsDown, faUsers , faAdd} from '@fortawesome/free-solid-svg-icons'

function DlgCreatePharm({showPharmCreation, setShowPharmCreate, person, userList, pharmMade, setPharmMade}) {


  const [processing, setProcessing]                            = useState(false);
  const [searchTerm, setSearchTerm]                            = useState('');
  const [filteredUsers, setFilteredUsers]                      = useState([]);
  const [selectedUser, setSelectedUser]                        = useState(null);
  const [userfound, setUserFound]                              = useState(false);
  const [searchingProcessing, setSearchingProcessing]          = useState(false);

  const nameRef                                = useRef();
  const streetRef                              = useRef();  
  const townRef                                = useRef();
  const cityRef                                = useRef();  
  const zipCodeRef                             = useRef();
  const professionRef                          = useRef();
  const phoneRef                               = useRef();
  const regionRef                              = useRef();


  async function HandleCreatingPharmacy(e) {
    e.preventDefault();  
    setProcessing(true);   

    try{
      const d = new Date();
      let year = d.getFullYear().toString().substr(2,2);
      let month = d.getMonth();

       var pharmcode = year + "" +month+ ""+ makeid(7);
      
       const pharmContent = {
        "name" : nameRef.current.value,
        "street" : streetRef.current.value,
        "town" : townRef.current.value,
        "city" : cityRef.current.value,
        "zipcode" : zipCodeRef.current.value,
        "pharmacycode" : pharmcode,
        "region" : regionRef.current.value,
        "email" : searchTerm,
        "phone" : phoneRef.current.value,
        "scriptcodes" : [],
        "pharmacyusers" : []
       }

       setProcessing(false);

       const response = await axios.post(CONSTANTS.API_URL + "pharmacy/", pharmContent, {
            headers: {
                token: "Bearer "+ person.accessToken
            }
        });
       console.log(response.data);
       setPharmMade(pharmMade + 1);
       setUserFound(false);
       //setUserFound(false);
        e.target.reset();
    } catch (err){
      console.log(err);
      setProcessing(false);   
    }       
  }

  const handleInputChange = (event) => {
    setSearchingProcessing(true);
    const term = event.target.value;
    setSearchTerm(term);

    // Filter users based on input value
    const filtered = userList.filter(
      (user) => user.email.toLowerCase().includes(term.toLowerCase())
    );
    console.log("&&&&&&&&&");
    console.log(filtered);
    setFilteredUsers(filtered);
  };

  const handleSelectUser = (user) => {
    console.log("+++++++++++++++");
    console.log(user);
    setSelectedUser(user);
    setSearchTerm(user.email);
    setFilteredUsers([]);
    setSearchingProcessing(false);
    setUserFound(true);
    // Do something with the selected user
    // For instance, you can set it in the state of your parent component
  };

  function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}
  return (
    <Dialog header="Create Pharmacy" visible={showPharmCreation} onHide={() => setShowPharmCreate(false)} style={{ width: '50vw' }}
      footer={
      <div>      
          <Button className="btn btn-picky" label="Close" onClick={() => setShowPharmCreate(false)} />
      </div>
      }>
    <div className="modal-body-content">
      <div className="form-item form-pharm">
                <div className="box-search-email">
                   <div className="flexme">
                      <div className="wide70">
                          <div className="form-group">
                              <input 
                                  type="text" 
                                  value={searchTerm}
                                  onChange={handleInputChange}
                                  placeholder="Email..." 
                                  className="form-control wide100" />
                          </div>
                          {
                            searchingProcessing && (
                              <div className="listing-search-items">
                                <ul className="no-style">
                                  {filteredUsers.map((user, index) => (
                                    <li key={index} onClick={() => handleSelectUser(user)}>
                                      {user.name} -  {user.email}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )
                          }
                      </div>
                      <div className="pod-creat-pharm">
                            {
                              userfound ? 
                              <span className="found-ic f-uptrue"><FontAwesomeIcon icon={faThumbsUp} /></span>
                              :
                              <span className="found-ic f-upfalse"><FontAwesomeIcon icon={faThumbsDown} /></span>
                            }
                      </div>
                      <div className="pdlf10">
                        {
                          selectedUser && (
                            <span>{selectedUser.name}</span>
                          )
                        }
                      </div>
                   </div>
                </div>

            <form onSubmit={HandleCreatingPharmacy}>                   
                    <div className="form-group">
                          <div className="lbldesc">Title:</div>
                          <input type="text" className="form-control wide100" ref={nameRef} required/>
                    </div>
                    
                    <div className="form-group">
                          <div className="lbldesc">Street:</div>
                          <input type="text" className="form-control wide100" ref={streetRef} required/>
                    </div>                    
              
                    <div className="form-group">
                          <div className="lbldesc">Town:</div>
                          <input type="text" className="form-control wide100" ref={townRef} required/>
                    </div>  
                    <div className="form-group">
                          <div className="lbldesc">City:</div>
                          <input type="text" className="form-control wide100" ref={cityRef} />
                    </div>
                    <div className="form-group">
                          <div className="lbldesc">ZipCode:</div>
                          <input type="text" className="form-control wide100" ref={zipCodeRef} />                                
                    </div>
                    <div className="form-group">
                          <div className="lbldesc">Phone Number:</div>
                          <input type="text" className="form-control wide100" ref={phoneRef} />
                    </div>
                    <div className="form-group">
                          <div className="lbldesc">Region:</div>
                          <select className="form-control wide100" ref={regionRef} >
                             <option value="Gauteng">Gauteng</option>
                             <option value="Mpumalanga">Mpumalanga</option>
                             <option value="North West">North West</option>
                             <option value="Limpopo">Limpopo</option>
                             <option value="Free State">Free State</option>
                             <option value="Kwazulu-Natal">Kwazulu-Natal</option>
                             <option value="Northern Cape">Northern Cape</option>
                             <option value="Western Cape">Western Cape</option>
                             <option value="Eastern Cape">Eastern Cape</option>
                          </select>
                    </div>

                   
                    {
                      userfound && (
                        <div className="form-group">
                            <button type="submit" className="btn btn-rescure" disabled={processing}>Create</button>                             
                      </div>
                      )
                    }
                 
            </form>
      </div>
        {
            processing && (
                <div className="img-source">
                   <img src={CONSTANTS.BLUE_PROCESSING} className="proc" />
                </div>
            )
        }
      </div>
    </Dialog>
  )
}

export default DlgCreatePharm