import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import DlgVideoDisplay from './../Dialogs/DlgVideoDisplay';


function VideoAdmin({videoAdminList, setVideoAdminList, videoAdminListArch, person}) {

    const [selectedVideo, setSelectedVideo]                   = useState();
    const [showWatchingVideo, setShowWatchingVideo]           = useState(false);
    const [globalFilter, setGlobalFilter]                     = useState('');

    function handleTableFilter(event) {
   
        const newData = videoAdminListArch.filter(row => {
            const searchText = event.target.value.toLowerCase();
          // return row.title.toLowerCase().includes(event.target.value.toLowerCase())
          return ( row.title.toLowerCase().includes(searchText)  )
        });
        setVideoAdminList(newData);
    }
     //////////___
     const header = (
      <div className="table-header flex justify-content-between">
        <h4 className="mx-0 my-1"></h4>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={handleTableFilter} placeholder="Search Video..." />
        </span>
      </div>
    );
    //////////___
      //////////___
    const linkVideoPopTemplate = (rowData) => {
    return <Button label="View" className="btn btn-rescure btsm1" onClick={() => clickVideoDisplay(rowData)}/>
    }
    //////////___

    const clickVideoDisplay = (data) => {
      setSelectedVideo(data);
      setShowWatchingVideo(true)
    }

  return (
    <div>
        <div className="display-video">
          { 
            videoAdminList && (
                <DlgVideoDisplay
                    showWatchingVideo={showWatchingVideo} 
                    setShowWatchingVideo={setShowWatchingVideo}
                    thisVideo={selectedVideo}
                    person={person}
                />
             )
          }
        </div>
        <DataTable
            value={videoAdminList}
            header={header}
            className="min-w-full"
            paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} pharmacy"
            responsiveLayout="scroll"
            globalFilter={(globalFilter) ? globalFilter : ''}
            >            
            <Column field="title" header="Title"></Column>
            <Column field="privacy" header="Backend"></Column>
            <Column field="videotype" header="Display"></Column>        
            <Column header="View" body={linkVideoPopTemplate}></Column>                     
        </DataTable>
    </div>
  )
}

export default VideoAdmin