import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import DlgVideoDisplay from './../Dialogs/DlgVideoDisplay';
import DlgVideoUpdate from '../Dialogs/DlgVideoUpdate';


function VideoPharmacy({videoPharmList, setVideoPharmList, videoPharmListArch, person}) {

    const [selectedVideo, setSelectedVideo]                                = useState();
    const [showWatchingVideo, setShowWatchingVideo]                        = useState(false);
    const [globalFilter, setGlobalFilter]                                  = useState('');

    //delete or update modal item
    const [optionFormat, setOptionFormat]                                  = useState();
    const [showUpdateModalFormat, setShowUpdateModalFormat]                = useState(false);

    function handleTableFilter(event) {
   
        const newData = videoPharmListArch.filter(row => {
            const searchText = event.target.value.toLowerCase();

          return ( row.title.toLowerCase().includes(searchText)  )
        });
        setVideoPharmList(newData);
    }
    
    //////////___
    const header = (
      <div className="table-header flex justify-content-between">
        <h4 className="mx-0 my-1"></h4>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={handleTableFilter} placeholder="Search Video..." />
        </span>
      </div>
    );
    //////////___
    //////////___
    const linkVideoPopTemplate = (rowData) => {
      return <Button label="View" className="btn btn-rescure btsm1" onClick={() => clickVideoDisplay(rowData)}/>
    }
    //////////___
    //////////___
    const updateVideoPopTemplate = (rowData) => {
      return <Button label="Edit" className="btn btn-rescure btsm1" onClick={() => clickUpdateVideoDisplay(rowData)}/>
    }
    //////////___
     //////////___
     const deleteVideoPopTemplate = (rowData) => {
      return <Button label="Delete" className="btn btn-rescure btsm1" onClick={() => clickDeleteVideoDisplay(rowData)}/>
    }
    //////////___
    const clickVideoDisplay = (data) => {
      setSelectedVideo(data);
      setShowWatchingVideo(true)
    }

    const clickUpdateVideoDisplay= (data) => {
      setSelectedVideo(data);
      //setShowWatchingVideo(true);

      console.log(data);
      setOptionFormat("edit")
      setShowUpdateModalFormat(true);
    }

    const clickDeleteVideoDisplay= (data) => {
      setSelectedVideo(data);
      //setShowWatchingVideo(true);

      console.log(data);
      setOptionFormat("delete")
      setShowUpdateModalFormat(true);
    }



  return (
    <div>
        <div className="display-video">
          { 
            videoPharmList && (
              <DlgVideoDisplay
                  showWatchingVideo={showWatchingVideo} 
                  setShowWatchingVideo={setShowWatchingVideo}
                  thisVideo={selectedVideo}
                  person={person}
              />
            )
          }

          {
            <DlgVideoUpdate 
                showUpdateModalFormat={showUpdateModalFormat}
                setShowUpdateModalFormat={setShowUpdateModalFormat}
                videoContent={selectedVideo}
                action={optionFormat}
                person={person}
              />
          }
        </div>
        <DataTable
            value={videoPharmList}
            header={header}
            className="min-w-full"
            paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} pharmacy"
            responsiveLayout="scroll"
            globalFilter={(globalFilter) ? globalFilter : ''}
            >            
            <Column field="title" header="Title"></Column>
            <Column field="privacy" header="Backend"></Column>
            <Column field="videotype" header="Display"></Column>        
            <Column header="View" body={linkVideoPopTemplate}></Column>  
            <Column header="Edit" body={updateVideoPopTemplate}></Column>        
            <Column header="Delete" body={deleteVideoPopTemplate}></Column>             
        </DataTable>
    </div>
  )
}

export default VideoPharmacy