import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux'
import * as CONSTANTS from "../CONSTANTS";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Link } from 'react-router-dom';

function ScriptActive() {
    const {user}                                                       = useSelector((state) => state.auth);
    const [scriptList, setScriptList]                                  = useState([]);
    const [scriptListArchive, setScriptListArchive]                    = useState([]);
    const [selectedScript, setSelectedScript]                          = useState(null);
    const [globalFilter, setGlobalFilter]                              = useState('');

    useEffect(() => {
        getAllTheScripts();        
    },[]);

    function handleTableFilter(event) {
    
        const newData = scriptListArchive.filter(row => {
            const searchText = event.toLowerCase();
        return ( 
            row.fullname.toLowerCase().includes(searchText) ||
            row.pharmacycode.toLowerCase().includes(searchText) ||
            row.scriptcode.toLowerCase().includes(searchText) ||
            row.status.text.toLowerCase().includes(searchText) )
        });
        setScriptList(newData);
    }
    //////////___
    const header = (
    <div className="table-header flex justify-content-between">
        <h4 className="mx-0 my-1"></h4>
        <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" onInput={(e) => handleTableFilter(e.target.value)} placeholder="Search Script..." />
        </span>
    </div>
    );
    //////////___
    const getAllTheScripts = async () => {
  
      try{
        const res = await axios.put(CONSTANTS.API_URL +"scripts/list/active", {
             headers: {
                 token: "Bearer "+ user.accessToken
             }
         });
        
            setScriptList(res.data);
            setScriptListArchive(res.data);

        }catch(erros){
        console.log(erros);     
        }
    }

    //////////___
    const showVersionTemplate = (rowData) => {     
        return rowData.versionCode + " / "+ rowData.version;
    }
    //////////___
    const showStatusTemplate = (rowData) => {
        return <div className={"stat stat" + rowData.status.text}>{rowData.status.text}</div>
    }
    //////////___
    const showExpiredDateTemplate = (rowData) => {
        return <span>{new Date(rowData.expiryDate).toLocaleDateString(undefined, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        })}</span>
    }
    //////////___
    const showCreatedDateTemplate = (rowData) => {
        return <span>{new Date(rowData.createdAt).toLocaleDateString(undefined, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        })}</span>
    }
    //////////___
    //////////___
    const linkScriptTemplate = (rowData) => {
        return <Link to={"/script-details/" + rowData._id} className="btn btn-rescure btsm1">View</Link> 
    }
    //////////___

  return (
    <div className="main-standard">
        <h2 className="lbl-title-page">Script Active</h2>
        <div className="card">
          <div className="card-body">
          <div className="row-data">
               {
                  scriptList && (
                    <DataTable
                        value={scriptList}
                        header={header}
                        className="min-w-full"
                        paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} script"
                        responsiveLayout="scroll"
                        globalFilter={(globalFilter) ? globalFilter : ''}
                        selection={selectedScript}
                        onSelectionChange={e => setSelectedScript(e.value)}
                        rowClassName={(rowData) => rowData.processed ? 'se-processed' : 'unse-processed'}
                      >
                        <Column header="Created" body={showCreatedDateTemplate}></Column>  
                        <Column field="fullname" header="Full Name"></Column>
                        <Column field="pharmacycode" header="Pharmacy Code"></Column>
                        <Column field="scriptcode" header="Script Code"></Column>  
                        <Column header="Version" body={showVersionTemplate}></Column>      
                        <Column header="Status" body={showStatusTemplate}></Column>   
                        <Column header="Expired" body={showExpiredDateTemplate}></Column>        
                        <Column header="View" body={linkScriptTemplate}></Column>                                                                
                    </DataTable>
                  )
              }
            </div>
          </div>
        </div>
    </div>
  )
}

export default ScriptActive