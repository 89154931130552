import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";

function TBVStorePricing({statusIndex, person}) {

    const [currentStorePricing, setStorePricing]                               = useState();
    const [currentCash, setCurrentCash]                                        = useState(false);
    const [settingsID, setSettingsID]                                          = useState(null);
    const [processing, setProcessing]                                          = useState();

    
    const [bankName, setBankName]                                              = useState("");
    const [branchCode, setBranchCode]                                          = useState("");
    const [branchName, setBranchName]                                          = useState("");
    const [accountNumber, setAccountNumber]                                    = useState("");
    const [accountType, setAccountType]                                        = useState("");

    useEffect(() => {
        if(statusIndex === 6){
            collectCurrentPricing();
        }
    },[statusIndex])

    const collectCurrentPricing = async () => {
        setProcessing(true);
        try{
            
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: CONSTANTS.API_URL + "settings/single",
                headers: { 
                  'token': 'Bearer ' + person.accessToken
                }
              };
              
            //))))))))
            const response = await axios.request(config);
            //))))))))
            setStorePricing(response.data.storePrice);
            setCurrentCash(response.data.cashonly);
            setSettingsID(response.data._id);
            //console.log("))))(((((");
            //console.log(response.data);
              ////////
              //Bank details
              setBankName(response.data.bank.bankName || "");
              setBranchName(response.data.bank.branchName || "");
              setBranchCode(response.data.bank.branchCode || "");
              setAccountNumber(response.data.bank.accountNumber || "");
              setAccountType(response.data.bank.accountType || "");

            setProcessing(false);
        }catch(err){
            setProcessing(false);
            console.log(err);
        }
    }

    const handleInStorePricing = async (e) => {
        e.preventDefault();

        setProcessing(true);
        try{

            const tokenUpdate = {
                    "storePricing" : currentStorePricing
                }

            const res = await axios.put(CONSTANTS.API_URL +"settings/update/store/pricing", tokenUpdate, {
                    headers: {
                        'Content-Type': 'application/json',
                        'token': "Bearer "+ person.accessToken
                    }
                });
          
            //console.log(res);
            toast.success("Price has been updated.");
            setProcessing(false);
        }catch(err){
            setProcessing(false);
            console.log(err);
            toast.error("Something went wrong updating the token. Please try again later.")
        }
    }

    const handleCurrentPricingString = (event) => {
        setStorePricing(event.target.value)
    }

    const handleCashPaymentOnly = async (value) => {
        const cashItem = {
            "settingsid" : settingsID,
            "status" : value
        }

        try{
            setProcessing(true);
            const res = await axios.put(CONSTANTS.API_URL +"settings/update/store/cash", cashItem, {
                headers: {
                    'token': "Bearer "+ person.accessToken
                }
            });
            //console.log(res.data);
            setProcessing(false);
            setCurrentCash(res.data.cashonly);

            if(res.data.cashonly === true){
                toast.success("All stores will only utilize cash payments");
            }else {
                toast.success("All stores will utilize both cash payments and Medical Aid.");
            }
            
        }catch(err){
            setProcessing(false);
            console.log(err);
            toast.success("Something went wrong. Please try again later.");
        }
    }

    const handleBankingDetails = async (e) => {
        e.preventDefault();

        try{
            const content = {
                "bankName": bankName,
                "branchCode" :branchCode,
                "branchName" : branchName,
                "accountNumber" : accountNumber,
                "accountType" : accountType
            }

            //console.log(content);
            setProcessing(true);

            const res = await axios.put(CONSTANTS.API_URL +"settings//update/banking/details", content, {
                headers: {
                    'token': "Bearer "+ person.accessToken
                }
            });
            
            //console.log(res.data);
            setProcessing(false);

            toast.success("Banking details have been updated.");          
        }catch(err){
            console.log(err);
            toast.danger("Banking details were not updated.");
        }
    }
    
  return (
    <div className="tv-box">
        <div className="block-section-item">
            <h3>In Store Pricing</h3>
            <div className="content-tab-view">
                <p>Set the pricing for cash payments.</p>
                <form onSubmit={handleInStorePricing}>                   
                    <div className="form-group">
                        <input type="text" className="form-control wide100" onChange={handleCurrentPricingString} value={currentStorePricing} required/>
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-rescure" disabled={processing}>Submit</button>                             
                    </div>                       
                </form>
            </div>
        </div>
        {
            settingsID !== null && (
                <div className="block-section-item">
                    <h3>Cash only</h3>
                    <div className="content-tab-view">
                        <p>Have medical aid payment disabled and utilize cash only in stores.</p>
                        {
                            (currentCash  === true) ? 
                            <div className="cont-button">
                                <button className="btn btn-rescure" onClick={() => handleCashPaymentOnly(false)} disabled={processing}>Enable Medical Aid Payment</button>
                            </div>
                            :
                            <div className="cont-button">
                                <button className="btn btn-warning" onClick={() => handleCashPaymentOnly(true)} disabled={processing}>Disable Medical Aid Payment</button>
                            </div>
                        }
                    </div>
                </div>
            )
        }
        <div className="block-section-item">
            <h3>Banking Details</h3>
            <div className="content-tab-view">
                <form onSubmit={handleBankingDetails}>
                    <div className="form-group">
                        <div className="txt-label">Bank Name*:</div>
                        <input 
                            type="text" className="form-control" 
                            onChange={(val) => setBankName(val.target.value)}
                            value={bankName} required/>
                    </div>
                    <div className="form-group">
                        <div className="txt-label">Branch Code*:</div>
                        <input type="text" className="form-control" 
                            onChange={(val) => setBranchCode(val.target.value)}
                            value={branchCode} required/>
                    </div>
                    <div className="form-group">
                        <div className="txt-label">Branch Name*:</div>
                        <input type="text" className="form-control" 
                            onChange={(val) => setBranchName(val.target.value)}
                            value={branchName}  required/>
                    </div>
                    <div className="form-group">
                        <div className="txt-label">Account Number*:</div>
                        <input type="text" className="form-control" 
                            onChange={(val) => setAccountNumber(val.target.value)}
                            value={accountNumber} required/>
                    </div>
                    <div className="form-group">
                        <div className="txt-label">Account Type:</div>
                        <input type="text" className="form-control" 
                            onChange={(val) => setAccountType(val.target.value)}
                            value={accountType} />
                    </div>
                    <div className="form-group">
                        <button className="btn btn-rescure" disabled={processing}>Submit</button>
                    </div>
                </form>
            </div>
        </div>
        {
            processing && (
                    <div className="img-source">
                    <img src={CONSTANTS.BLUE_PROCESSING} className="proc" />
                    </div>
            )
        }
    </div>
  )
}

export default TBVStorePricing