import React, { useEffect, useRef, useState } from 'react';
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { toast } from 'react-toastify';

function TBVEmailNotifications({person}) {
    const [processing, setProcessing]                                          = useState(false);
    const [emailNotifications, setEmailNotifications]                          = useState([]);
    const [emailUpdate, setEmailUpdate]                                        = useState();
    const [currentSetting, setCurrentSetting]                                  = useState();

    const emailAddressRef                                                      = useRef();

    useEffect(() => {
       collectInitalEmail()
    },[emailUpdate]);


    const collectInitalEmail = async () => {
      
        try{
            const res = await axios.get(CONSTANTS.API_URL +"settings/");
             //console.log(res);
             //console.log("users");
             //console.log(res.data);
             setEmailNotifications(res.data.emailnotifications);
             setCurrentSetting(res.data);
        }catch(erros){
          console.log(erros);         
        }
    }

    async function HandleAddingEmails(e) {
        e.preventDefault();  
        setProcessing(true);   
    
        try{
 
           const emailContent = {
            "emailaddress" : emailAddressRef.current.value
           }
  
           const response = await axios.post(CONSTANTS.API_URL + "settings/push/email", emailContent, {
                headers: {
                    token: "Bearer "+ person.accessToken
                }
            });
           //console.log(response.data);

 
            setEmailUpdate((prevValue) => prevValue + 1);
           //setUserFound(false);
            e.target.reset();
            setProcessing(false); 
            toast.success("Email has been added successfully.")
        } catch (err){
          console.log(err);
          setProcessing(false);   
        }       
      }
    
      const removeCurrentEmail = async (email) => {
        try{

            const popEmail = {
                "id" : currentSetting._id,
                "email": email
            }

    
            const response = await axios.put(CONSTANTS.API_URL + "settings/pull/email", popEmail, {
                headers: {
                    token: "Bearer "+ person.accessToken
                }
            });
    
            console.log(response);
            setEmailUpdate((prevValue) => prevValue + 1);
        }catch(err){
            console.log(err);
            toast.error("Something went wrong deleting, please try again later.")
        }
      }
  return (
    <div className="tv-box">
        <h3>Email Notifications</h3>
        <div className="content-tab-view">

           <div className="flexme">
                <div className="flex_sub50">
                    <form onSubmit={HandleAddingEmails}>                   
                            <div className="form-group">
                                <div className="lbldesc">Email Address:</div>
                                <input type="email" className="form-control wide100" ref={emailAddressRef} required/>
                            </div>
                            
                            <div className="form-group">
                                    <button type="submit" className="btn btn-rescure" disabled={processing}>Create</button>                             
                            </div>                       
                    </form>
                </div>
                <div className="flex_sub50">
                    {
                        emailNotifications && (
                            <div className="l-list-container">
                                <ul className="l-list no-style">
                                    {
                                        emailNotifications.map((email, key) => {
                                            return (<li key={key} className="tab-bar-ls">
                                                {email}
                                                <div className="clox-remove" onClick={()=> removeCurrentEmail(email,)}>X</div>
                                            </li>)
                                        })
                                    }
                                </ul>
                            </div>
                        )
                    }
                </div>
           </div>
           {
            processing && (
                    <div className="img-source">
                    <img src={CONSTANTS.BLUE_PROCESSING} className="proc" />
                    </div>
                )
            }
        </div>
    </div>
  )
}

export default TBVEmailNotifications