import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Sidebar from '../Components/Side/Sidebar';
import Tobnavbar from '../Components/Top/Tobnavbar';


function Dashboard() {

    const navigate                          = useNavigate();
    const {user}                            = useSelector((state) => state.auth)


    useEffect(() => {
        if(!user){
            navigate("/login")
        }
    },[user, navigate])

  return (
    <div className="dashingboard-outer">   
        <div className="dashingboard">
           <div className="side-dash">
                <Sidebar member={user} />
            </div>
           
            <div className="main-dash-content">
                    <div className="top-nav">
                        <Tobnavbar />
                    </div>
                    <div className="main-content-arena">
                        <Outlet />
                    </div>
            </div>
        </div>
    </div>
  )
}

export default Dashboard