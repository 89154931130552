import React, { useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { toast } from 'react-toastify';

function DlgCreateVideo({showURLCreation, setShowURLCreation, person}) {
    const [processing, setProcessing]                            = useState(false);
    const [showBackendVideo, setShowBackendVideo]                = useState(true);

    const videoURLRef                                     = useRef();
    const titleRef                                        = useRef();
    const videoDisplayRef                                 = useRef();
    const txtDescriptionRef                               = useRef();

    async function HandleCreatingVideoData(e) {
        e.preventDefault();  
        setProcessing(true);   
    
        try{
            const videosContent = {
                "title" : titleRef.current.value,
                "videourl" : videoURLRef.current.value,
                "videotype": videoDisplayRef.current.value,
                "description": txtDescriptionRef.current.value,
                "privacy": showBackendVideo
               }
                      
               const responseReg = await axios.post(CONSTANTS.API_URL+"videos/create/content", videosContent, {
                    headers: {
                        token: "Bearer "+ person.accessToken
                    }
                });
               //console.log(responseReg.data);
               setProcessing(false);
               e.target.reset();
               toast.success("You successfully created a video.");
                
        } catch (err){
    
          console.log(err);
          setProcessing(false);
          toast.error("Something went wrong, please try again later.")
        }       
      }

      const actionShowBackendVideoChange = (checkOption) => {
       
        setShowBackendVideo(!showBackendVideo);
      }

  return (
    <Dialog header="Create Video" visible={showURLCreation} onHide={() => setShowURLCreation(false)} style={{ width: '50vw' }}
        footer={
        <div>      
            <Button className="btn btn-picky" label="Close" onClick={() => setShowURLCreation(false)} />
        </div>
        }>
    <div className="modal-body-content">
      <div className="data-item list-video">
          <form onSubmit={HandleCreatingVideoData}>
                   
                    <div className="form-group">
                          <div className="lbldesc">Title:</div>
                          <input type="text" className="form-control wide100" ref={titleRef}  />
                    </div>     
                    <div className="form-group">
                          <div className="lbldesc">Video URL:</div>
                          <input type="text" className="form-control wide100" ref={videoURLRef}  />
                    </div>   
                    <div className="form-group">
                          <div className="lbldesc">Display type:</div>
                          <select className="form-control wide100" ref={videoDisplayRef}  >
                              <option value="client">Client Pharmacy</option>
                              <option value="admin">Admin Pharmacy</option>
                          </select>
                    </div>   
                    <div className="form-group">
                          <div className="lbldesc">Show Admin:</div>
                          <div className="form-group">
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={showBackendVideo}
                                    onChange={() => actionShowBackendVideoChange(!showBackendVideo)}
                                  />
                                 <span className="mgrite5"> Show Backend</span>
                                </label>
                              </div>
                         
                    </div> 
                    <div className="form-group">
                        <div className="lbldesc">
                          Describe the video
                        </div>  
                        <div className="form-group">
                            <textarea className="form-control" ref={txtDescriptionRef}></textarea>
                        </div>
                    </div>   
                    
                    <div className="form-group">
                          <button type="submit" className="btn btn-rescure" disabled={processing}>Create</button>                             
                    </div>
          </form>
      </div>
        {
            processing && (
                <div className="img-source">
                   <img src={CONSTANTS.BLUE_PROCESSING} className="proc" />
                </div>
            )
        }
      </div>
    </Dialog>
  )
}

export default DlgCreateVideo