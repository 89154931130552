import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DlgCreateVideo from '../Components/Dialogs/DlgCreateVideo';
import axios from 'axios';
import * as CONSTANTS from "../CONSTANTS";
import { Button } from 'primereact/button';
import './../Components/Other/tab.css';
import VideoAdmin from '../Components/Other/VideoAdmin';
import VideoPharmacy from '../Components/Other/VideoPharmacy';
import Tabs from '../Components/Other/Tabs';

function Knowledge() {
  const {user}                                       = useSelector((state) => state.auth);

  const [showURLCreation, setShowURLCreation]               = useState();

  const [videoAdminList, setVideoAdminList]                  = useState([]);
  const [videoPharmList, setVideoPharmList]                  = useState([]);
  const [videoAdminListArch, setVideoAdminListArch]                  = useState([]);
  const [videoPharmListArch, setVideoPharmListArch]                  = useState([]);

  const [videoListing, setVideoListing]                     = useState([]);
  const [videoListingArchive, setVideoListingArchive]       = useState([]);

  const [vidCounter, setVideoCounter]                       = useState(0);

  const [activeTab, setActiveTab]                           = useState(1);

  const tabs = [
    { id: 1, label: 'Admin Videos' },
    { id: 2, label: 'Pharmacy Videos' },
  ];

  useEffect(() => {
   getVideoCollectionAdmin();
   getVideoCollectionPharm();
  }, [])
  
  const getVideoCollectionAdmin = async () => {
      try{
          const res = await axios.get(CONSTANTS.API_URL +"videos/list/toadmin/admin", {
                headers: {
                    token: "Bearer "+ user.accessToken
                }
            });
            
          // console.log("Pharmacies");
          // console.log(res.data);
           setVideoAdminList(res.data);
           setVideoAdminListArch(res.data);
      }catch(erros){
        console.log(erros);     
      }
  }

  const getVideoCollectionPharm = async () => {
    try{
        const res = await axios.get(CONSTANTS.API_URL +"videos/list/topharm/client", {
              headers: {
                  token: "Bearer "+ user.accessToken
              }
          });
         
        // console.log("Pharmacies");
        // console.log(res.data);
         setVideoPharmList(res.data);
         setVideoPharmListArch(res.data);
    }catch(erros){
      console.log(erros);     
    }
}


  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  return (
    <div className="main-standard">
      <h2 className="lbl-title-page">Knowledge</h2>
        <div className="card">
          <div className="card-body">
            <div className="row-box">
                <DlgCreateVideo 
                      showURLCreation={showURLCreation} 
                      setShowURLCreation={setShowURLCreation} 
                      setVideoCounter={setVideoCounter}      
                      person={user}                
                    />

                  <Button
                        label="Create Videos"
                        className="btn btn-rescure btsm1 mr-2"
                        onClick={() => setShowURLCreation(true)}
                      />
              </div>
                             
              <div className="row-data">
               {
                  videoAdminList && (
                    <div className="app">
                    <div className="tabs">
                      {
                      tabs.map((tab) => (
                          <Tabs
                            key={tab.id}
                            label={tab.label}
                            content={tab.content}
                            isActive={activeTab === tab.id}
                            onClick={() => handleTabClick(tab.id)}
                          />
                        ))
                      }
                    </div>
                    <div className="content tab-box">
                      {tabs.map((tab) => (
                        <div key={tab.id} className={`tab-content ${activeTab === tab.id ? 'active' : ''}`}>
                          {tab.id === 1 && <VideoAdmin videoAdminList={videoAdminList} setVideoAdminList={setVideoAdminList} videoAdminListArch={videoAdminListArch} person={user} />}
                          {tab.id === 2 && <VideoPharmacy videoPharmList={videoPharmList} setVideoPharmList={setVideoPharmList} videoPharmListArch={videoPharmListArch} person={user} />}
                        </div>
                      ))}
                    </div>
                  </div>
                  )
              }
            </div>

          </div>
        </div>
    </div>
  )
}

export default Knowledge