//import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./Pages/Login";
import Dashboard from "./Pages/Dashboard";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Home from "./Pages/Home";
import UsersList from "./Pages/UsersList";
import PharmacyDetails from "./Pages/PharmacyDetails";
import Pharmacies from "./Pages/Pharmacies";
import Knowledge from "./Pages/Knowledge";
import ScriptArchive from "./Pages/ScriptArchive";
import ScriptCancel from "./Pages/ScriptCancel";
import ScriptActive from "./Pages/ScriptActive";
import ScriptDetails from "./Pages/ScriptDetails";
import Settings from "./Pages/Settings";
import FlagsPage from "./Pages/FlagsPage";

function App() {
  return (
    <>
     <Router>
        <div className="main-container">
          <Routes>              
             <Route path="/login" element={<Login />}/>  
             <Route element={<Dashboard />}>
                 <Route path="/" element={<Home/>}/> 
                 <Route path="/dashboard" element={<Home/>}/> 
                 <Route path="/users" element={<UsersList/>}/> 
                 <Route path="/pharmacy/:id" element={<PharmacyDetails/>}/> 
                 <Route path="/pharmacies" element={<Pharmacies/>}/> 
                 <Route path="/settings" element={<Settings/>}/> 
                 <Route path="/expired-scripts" element={<ScriptArchive/>}/> 
                 <Route path="/cancelled-scripts" element={<ScriptCancel/>}/> 
                 <Route path="/active-scripts" element={<ScriptActive/>}/> 
                 <Route path="/script-details/:id" element={<ScriptDetails/>}/> 
                 <Route path="/knowledge-base" element={<Knowledge/>}/> 
                 <Route path="/flags" element={<FlagsPage />} />
             </Route>
          </Routes>
        </div>
     </Router>
     <ToastContainer />
    </>
  );
}

export default App;
