import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import io from "socket.io-client";
import * as CONSTANTS from "../CONSTANTS";
import { useDispatch, useSelector } from 'react-redux';
import { logout } from './../reduxAuth/authSlice';
import ScrollToBottom from "react-scroll-to-bottom";
const socket  = io.connect(CONSTANTS.API_BASE);

function FlagsPage() {
    const {user}                                                                = useSelector((state) => state.auth);

    const [listOfFlags, setListOfFlags]                                         = useState([]);
    
    const [currentChatId, setCurrentChatId]                                     = useState();
    const [currentMessage, setCurrentMessage]                                   = useState();
    const [currentFlag, setCurrentFlag]                                         = useState();
    const [messageList, setMessageList]                                         = useState([]);

    const [connectioOn, setConnectionOn]                                = useState(false);
    const [beginChat, setBeginChat]                                     = useState(false);

    const [processing, setProcessing]                                           = useState(false);
    const dispatch                                                              = useDispatch();

    useEffect(() => {
        collectCurrentFlags();
    },[])

    useEffect(() => {
        if(beginChat === true){
            collectCurrentFlags();
        }
    },[beginChat])

    useEffect(() => {
        if(currentFlag){
          if(connectioOn === false) {
            setConnectionOn(true);
          }     
        }

        if(beginChat === true){
            setBeginChat(false);
        }
    },[currentFlag])

    useEffect(() => {
        socket.on("receive_message", (data) => {
            console.log("socket");
            console.log(data);
            setMessageList((list) => [...list, data]);
        });
    
    },[socket])

    const collectCurrentFlags = async () => {
        try{
          
          const resFlags = await axios.get(CONSTANTS.API_URL + "users/list/filtered/flags",  {
                headers: {
                    token: "Bearer "+ user.accessToken
                }
            });
            console.log(resFlags.data);
            setListOfFlags(resFlags.data);
          
        } catch(err){
          console.log(err);
             //Check if token is invalid     
             if(err.response.status === 403){
                localStorage.removeItem(CONSTANTS.SESSION_COOKIE);
                dispatch(logout());
                toast.error("Session has timeout out.");

                setTimeout(() => {
                     document.location.href("/login");
                }, 4000);
            }
        }
      }

      const joinChannelForDiscussion = async () => {
    
        const curOb = {
            "roomid" : currentFlag.roomid,
        }
        
        //Change Flag to Seen
        const resMessageHistory = await axios.put(CONSTANTS.API_URL + "settings/message/v1/history", curOb, {
            headers: {
                token: "Bearer "+ user.accessToken
            }
        });
        console.log(resMessageHistory.data);
        setMessageList(resMessageHistory.data);
        //Change Flag to Seen
        const resSeenUpdate = await axios.put(CONSTANTS.API_URL + "settings/update/flag/seen", curOb, {
            headers: {
                token: "Bearer "+ user.accessToken
            }
        });

       // console.log(resSeenUpdate);
        setCurrentChatId(currentFlag.chatid.slice(-1)[0].toString());
        setBeginChat(true);
        console.log("ROOMID:");
        console.log(currentFlag.roomid);
        socket.emit("join_room", currentFlag.roomid);
      
    }

    const incrementScriptDownload  = async() => {
        try{
            setProcessing(true);
            let config = {
                method: 'put',
                maxBodyLength: Infinity,
                url: CONSTANTS.API_URL + "scripts/print/limit/update/" + currentFlag.scriptcode,
                headers: { 
                  'token': 'Bearer '+ user.accessToken
                }
              };

            const resPrintUpdae = await axios.request(config);   
            //console.log(resPrintUpdae);

            if(resPrintUpdae.status === 200){
                toast.success("You successfully increased the limit.")
            }else{
                toast.error("Update was not successful, please try again later.")
            }
            setProcessing(false);
        }catch(err){
            console.log(err);
            toast.error("Something went wrong, please try again later.");
            setProcessing(false);
        }
   
    }
    const extentDownloadDate = async() => {
        //console.log(currentFlag);
        try{
            setProcessing(true);

            let config = {
                method: 'put',
                maxBodyLength: Infinity,
                url: CONSTANTS.API_URL + "scripts/print/duration/timestamp/update/" + currentFlag.scriptcode,
                headers: { 
                  'token': 'Bearer '+ user.accessToken
                }
              };

            const resPrintTimStramp = await axios.request(config);
            
            // console.log(resPrintTimStramp);

            if(resPrintTimStramp.status === 200){
                toast.success("You successfully extended the date of script download.")
            }else{
                toast.error("Update was not successful, please try again later.")
            }
            setProcessing(false);
        }catch(err){
            console.log(err);
            toast.error("Something went wrong, please try again later.");
            setProcessing(false);
        }
       
    }
    ////////////////////////
  const sendMessageChat = async() => {
    try{
        //patientDetails
            if(currentMessage !== "" ){
                const messageData = {
                    room: currentFlag.roomid,
                    chatid: currentChatId,
                    message: currentMessage,
                    time: new Date(Date.now()).getHours() + ":" + new Date(Date.now()).getMinutes(),
                    author: {
                        senderid: user._id,
                        sendername: user.name
                    }
                }

                //console.log("))))))))))))))))");
                console.log(messageData);
                
                await socket.emit("send_message", messageData);
                setMessageList((list) => [...list, messageData]);
                setCurrentMessage("");
                
            }
        }catch(err){
            console.log(err);
        }
    }


  return (
    <div className="main-standard">
        <h2 className="lbl-title-page">Notifications</h2>
        <div className="card">
            <div className="card-body">
                <div className="flexme">
                    <div className="flex_sub50">
                        <div className="container-list-flags">
                        {
                            listOfFlags && listOfFlags.length > 0  && (
                                <ul className="no-style">
                                {
                                    listOfFlags.map((flaging, index) => {
                                    return <li key={index} className={"item-li bls-" +flaging.seen}>
                                        <div className="content-line">
                                            <button className="btn btn-listing btn-full" onClick={() => setCurrentFlag(flaging)}>
                                               
                                               <div className="flexme">
                                                    <div className="flex_sub20">
                                                       <div className="profile-chanel"></div>
                                                    </div>
                                                    <div className="flex_sub80">
                                                        <div className="top-chanel-text">
                                                        <strong>({flaging.scriptcode})</strong> | <span className="lg-small">{flaging.titlePharm}</span>
                                                        </div>
                                                        <div className="bottom-chanel-text">
                                                            last active: {
                                                            new Date(flaging.updatedAt).toLocaleDateString(undefined, {
                                                                    year: 'numeric',
                                                                    month: 'long',
                                                                    day: 'numeric',
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                               </div>
                                            </button>
                                        </div>
                                    </li>
                                    })
                                }
                                </ul>
                            )      
                        }
                        </div>
                    </div>
                    <div className="flex_sub50">
                      {
                        currentFlag && (
                            <div className="cont-info-bow">
                                <strong>{currentFlag.roomid}</strong>
                                <p>{currentFlag.contentText}</p>
                                <div className="fl-button-sol"> 
                                    <button className="btn btn-rescure mgright5" onClick={joinChannelForDiscussion}>Join</button>
                                    {
                                        beginChat && (
                                            <button className="btn btn-rescure mgright5" onClick={incrementScriptDownload}>Increase Download limit</button>
                                        )
                                    }
                                    {
                                        beginChat && (
                                            <button className="btn btn-rescure" onClick={extentDownloadDate}>Extent Date</button>
                                        )
                                    }
                                    
                                </div>
                            </div>
                            )
                        }

                        {
                            beginChat && (<div className="main-modal">
                                <div className="chat-header">
                                    <p>Live Chat</p>
                                </div>
                                <div className="chat-body">  
                                    <div className="chat-window-body" >
                                    <ScrollToBottom className="control-chat-scroll">
                                        {
                                            messageList.map((message, index) => {
                                                return <div className={`message-buble ${user._id === message.author.senderid ? "your-message": "others-message" }`} key={index}>
                                                <div className="titling-buble">
                                                    {message.author.sendername}
                                                </div>
                                                <div className="message-text">
                                                {message.message}
                                                </div>
                                                <div className="time-meta">{message.time}</div>
                                            </div>
                                            })
                                        } 
                                        </ScrollToBottom>
                                    </div>                          
                                </div>
                                <div className="chat-footer">
                                    <input 
                                        className="form-control txt-message"
                                        type="text"
                                        placeholder="Type Message..."
                                        onChange={(event) => {
                                            setCurrentMessage(event.target.value);
                                        }}     
                                        value={currentMessage}                       
                                        onKeyPress={(event) => {
                                            event.key === "Enter" && sendMessageChat();
                                        }}
                                        />                                            
                                        <button className="btn btn-rescure" onClick={sendMessageChat}>&#9658;</button>
                                </div>
                            </div>)
                        }
                    </div>
                </div>
                {
                    processing && (
                        <div className="position-center">
                        <img src={CONSTANTS.BLUE_PROCESSING} className="proc" />
                        </div>
                    )
                    }
            </div>
        </div>
    </div>
  )
}

export default FlagsPage