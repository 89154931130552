import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";

function TBVMedicationToken({statusIndex, person}) {

    const [currentToken, setCurrentToken]                               = useState();
    const [processing, setProcessing]                                   = useState();

    useEffect(() => {
        if(statusIndex === 4){
            collectCurrentToken();
        }
    },[statusIndex])

    const collectCurrentToken = async () => {
        setProcessing(true);
        try{
            
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: CONSTANTS.API_URL + "settings/single",
                headers: { 
                  'token': 'Bearer ' + person.accessToken
                }
              };
              
            //))))))))
            const response = await axios.request(config);
            //))))))))
           
            setCurrentToken(response.data.medicationToken);
            setProcessing(false);
        }catch(err){
            setProcessing(false);
            console.log(err);
        }
    }

    const handlePrescriptionToken = async (e) => {
        e.preventDefault();

        setProcessing(true);
        try{

            const tokenUpdate = {
                "medictoken" : currentToken
            }

            const res = await axios.put(CONSTANTS.API_URL +"settings/update/token", tokenUpdate, {
                    headers: {
                        'Content-Type': 'application/json',
                        'token': "Bearer "+ person.accessToken
                    }
                });
          
            //console.log(res);
            toast.success("Token has been updated.");
            setProcessing(false);
        }catch(err){
            setProcessing(false);
            console.log(err);
            toast.error("Something went wrong updating the token. Please try again later.")
        }
    }

    const handleCurrentTokenString = (event) => {
        //console.log(event.target.value);
        setCurrentToken(event.target.value)
    }

  return (
    <div className="tv-box">
        <h3>Medication Token</h3>
        <div className="content-tab-view">
            <p>Token used to allow the prescription medication.</p>
            <form onSubmit={handlePrescriptionToken}>                   
                <div className="form-group">
                    <input type="text" className="form-control wide100" onChange={handleCurrentTokenString} value={currentToken} required/>
                </div>
                <div className="form-group">
                    <button type="submit" className="btn btn-rescure" disabled={processing}>Create</button>                             
                </div>                       
            </form>
        </div>
    </div>
  )
}

export default TBVMedicationToken